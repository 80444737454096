@import "styles/_colors.scss";
@import "styles/_dimens.scss";

.individual-classification {
    position: relative;

    .image-dropzone {
        position: absolute;
        right: 53%;

        .dropzone {
            border: 2px dashed $color-primary;
            cursor: pointer;
            height: 300px;
            width: 300px;

            .dropzone-text {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                text-align: center;
                width: 100%;
            }
        }
    }

    .classification-results {
        align-items: center;
        display: flex;
        flex-direction: column;
        position: fixed;
        left: 53%;

        .classification-image-container {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 300px;

            .classification-image {
                max-width: 300px;
                max-height: 300px;
            }
        }

        .classification-image-empty {
            align-items: center;
            background-color: lighten($color-text-secondary, 40%);
            display: flex;
            flex-direction: column;
            height: 300px;
            justify-content: center;
            width: 300px;
        }

        .classification-predictions {
            display: flex;
        }
    }
}

