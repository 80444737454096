@import "styles/_colors.scss";
@import "styles/_dimens.scss";

.post-prediction__container {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: 18px;
    margin: $default-spacing*2.5;

    .post-prediction__header {
        font-weight: bold;
    }

    .post-prediction {
        color: $color-text-secondary;
        margin-top: $default-spacing * 1.5;

        &.post-prediction--dank {
            color: $color-primary;
            font-weight: bold;
        }
    }

    .loading-spinner {
        margin-top: $default-spacing * 1.5;
        height: $default-spacing * 2;
        width: $default-spacing * 2;
    }
}
